import axios from 'axios';
// yup
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
// @mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useBoolean } from 'src/hooks/use-boolean';
// components
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { BlockItem, RHFTextField } from 'src/components/hook-form';
import Stack from '@mui/material/Stack';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';

// ----------------------------------------------------------------------

export default function SupportPopup() {
  const openForm = useBoolean();

  const { enqueueSnackbar } = useSnackbar();

  const Schema = Yup.object().shape({
    name: Yup.string().required('Bạn chưa nhập tên tập tin !'),
    phone: Yup.string().required('Bạn chưa nhập số điện thoại !'),
    content: Yup.string().required('Bạn chưa nhập vấn đề gặp phải !'),
  });

  const defaultValues = {
    name: '',
    phone: '',
    content: '',
  };

  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data: any) => {
    const CHANNEL_ID = '@congtyiit';
    const TELEGRAM_BOT_TOKEN = '7237858011:AAFb31eiCipmneKGV7tizDS0uNsl68aVhso';
    const TELEGRAM_API_URL = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;
    try {
      await axios.post(TELEGRAM_API_URL, {
        chat_id: CHANNEL_ID,
        text: `CÓ YÊU CẦU HỖ TRỢ MỚI\nTên khách hàng: ${data.name}\nSố điện thoại: ${data.phone}\n Nội dung hỗ trợ: ${data.content}`,
      });
      enqueueSnackbar(`Đã gửi yêu cầu hỗ trợ thành công !`);
      reset();
      openForm.onFalse();
    } catch (error) {
      enqueueSnackbar('Không tìm thấy kết nối mạng !', { variant: 'error' });
    }
  });
  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={openForm.onTrue}
        sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 9999 }}
      >
        Gửi yêu cầu hỗ trợ ?
      </Button>
      <Dialog
        fullWidth
        maxWidth={false}
        open={openForm.value}
        onClose={openForm.onFalse}
        PaperProps={{
          sx: { maxWidth: 620 },
        }}
      >
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <DialogTitle>Gửi yêu cầu hỗ trợ</DialogTitle>

          <DialogContent>
            <Stack spacing={2}>
              <BlockItem label="Họ và tên:" required>
                <RHFTextField name="name" label="Nhập vào họ tên..." />
              </BlockItem>

              <BlockItem label="Số điện thoại:" required>
                <RHFTextField name="phone" label="Nhập vào số điện thoại..." />
              </BlockItem>

              <BlockItem label="Vấn đề gặp phải:" required>
                <RHFTextField
                  multiline
                  rows={4}
                  name="content"
                  label="Mô tả về vấn đề bạn gặp phải..."
                />
              </BlockItem>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={openForm.onFalse}>
              Huỷ bỏ
            </Button>

            <LoadingButton type="submit" color="primary" variant="contained" loading={isSubmitting}>
              Gửi yêu cầu hỗ trợ
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
}
