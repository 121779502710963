import { useCallback, useMemo, useState } from 'react';
// context
import { TableContext } from './table-context';

// ----------------------------------------------------------------------

const initialState = {
  _table_data: null,
  _table_header: null,
  _table_rows: null,
  _table_row_selected: null,
  _table_row_collapse: null,
  _table_import_data: null,
  _table_export_data: null,
  _table_config: {
    table_name: '',
    add_new: false,
    //
    filter_by_date: false,
    filter_by_type: false,
    filter_by_search: false,
    //
    import_data: false,
    export_data: false,
    //
    table_selected: false,
    allocation_student: false,
    delete_all: false,
    collapse_item: false,
    //
    watch_row: false,
    print_row: false,
    edit_row: false,
    delete_row: false,
  },
  //
  _table_open_form: false,
};

type Props = {
  children: React.ReactNode;
};

export function TableProvider({ children }: Props) {
  const [values, setValues] = useState(initialState);

  // cập nhật state
  const setValue = useCallback(
    (name: string, value: any) => {
      setValues((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [setValues]
  );

  // lấy dữ liệu cho bảng
  const onSetTableData = useCallback(
    (data: any) => {
      setValue('_table_data', data);
    },
    [setValue]
  );

  // lấy dữ liệu header của bảng
  const onSetTableHeader = useCallback(
    (header: any) => {
      setValue('_table_header', header);
    },
    [setValue]
  );

  // lấy dữ liệu component các hàng của bảng
  const onSetTableRows = useCallback(
    (rows: any) => {
      setValue('_table_rows', rows);
    },
    [setValue]
  );

  // lấy dữ liệu hàng đang được chọn
  const onSetTableRowSelected = useCallback(
    (row: any) => {
      setValue('_table_row_selected', row);
    },
    [setValue]
  );

  // lấy dữ liệu component của item của hàng trong bảng
  const onSetTableRowCollapse = useCallback(
    (rows: any) => {
      setValue('_table_row_collapse', rows);
    },
    [setValue]
  );

  // lấy dữ liệu import từ file excel
  const onSetImportTableData = useCallback(
    (data: any) => {
      setValue('_table_import_data', data);
    },
    [setValue]
  );

  // lấy dữ liệu export ra file excel
  const onSetExportTableData = useCallback(
    (data: any) => {
      setValue('_table_export_data', data);
    },
    [setValue]
  );

  // lấy dữ liệu config của bạn
  const onSetConfigTable = useCallback(
    (data: any) => {
      setValue('_table_config', data);
    },
    [setValue]
  );

  // set trạng thái đóng mở của form
  const onSetOpenForm = useCallback(
    (open: any) => {
      setValue('_table_open_form', open);
    },
    [setValue]
  );

  // thêm dữ liệu mới vào bảng
  const onCreateNewRow = useCallback(
    (newRow: any) => {
      // @ts-ignore
      setValue('_table_data', [newRow, ...values._table_data]);
    },
    [setValue, values._table_data]
  );

  // thêm dữ liệu mới vào bảng
  const onCreateNewManyRow = useCallback(
    (newRows: any) => {
      // @ts-ignore
      setValue('_table_data', [...newRows, ...values._table_data]);
    },
    [setValue, values._table_data]
  );

  // cập nhật dữ liệu trong bảng
  const onUpdateRow = useCallback(
    (updateRow: any) => {
      // @ts-ignore
      const updated = values._table_data.map((item: any) => {
        if (item.id === updateRow.id) {
          return updateRow;
        }
        return item;
      });
      setValue('_table_data', updated);
    },
    [setValue, values._table_data]
  );

  const memoizedValue = useMemo(
    () => ({
      ...values,
      onSetTableData,
      onSetTableHeader,
      onSetTableRows,
      onSetTableRowSelected,
      onSetTableRowCollapse,
      //
      onSetImportTableData,
      onSetExportTableData,
      //
      onSetConfigTable,
      //
      onSetOpenForm,
      //
      onCreateNewRow,
      onCreateNewManyRow,
      onUpdateRow,
    }),
    [
      values,
      onSetTableData,
      onSetTableHeader,
      onSetTableRows,
      onSetTableRowSelected,
      onSetTableRowCollapse,
      //
      onSetImportTableData,
      onSetExportTableData,
      //
      onSetConfigTable,
      //
      onSetOpenForm,
      //
      onCreateNewRow,
      onCreateNewManyRow,
      onUpdateRow,
    ]
  );

  return <TableContext.Provider value={memoizedValue}>{children}</TableContext.Provider>;
}
