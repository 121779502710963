import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  lesson_plans: icon('lesson_plans'),
  library: icon('library'),
  school: icon('school'),
  classroom: icon('classroom'),
  subject: icon('subject'),
  category: icon('category'),
  student: icon('student'),
  test: icon('test'),
  transcript: icon('transcript'),
  convert: icon('convert'),
  calendar: icon('calendar'),
  game: icon('game'),
  scorm: icon('scorm'),
  mind_map: icon('mind_map'),
  chat_ai: icon('chat_ai'),
  learning_online: icon('learning_online'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      // Học liệu

      // ----------------------------------------------------------------------

      {
        subheader: 'Học liệu',

        items: [
          {
            title: 'Giáo án',
            path: paths.home.lesson_plans.root,
            icon: ICONS.lesson_plans,
            children: [
              {
                title: 'Soạn văn bản',
                path: paths.home.lesson_plans.writing,
              },
              {
                title: 'Soạn giáo án',
                path: paths.home.lesson_plans.prepare_lesson_plans,
              },
            ],
          },
          {
            title: 'Thư viện',
            path: paths.home.library.root,
            icon: ICONS.library,
          },
          { title: 'Tư duy', path: paths.home.mind_map.root, icon: ICONS.mind_map },
        ],
      },
      // Danh mục
      {
        subheader: 'Danh mục',

        items: [
          {
            title: 'Trường học',
            path: paths.home.school.root,
            icon: ICONS.school,
          },

          {
            title: 'Lớp học',
            path: paths.home.classroom.root,
            icon: ICONS.classroom,
            children: [
              {
                title: 'Danh sách lớp học',
                path: paths.home.classroom.all_classroom,
              },
              {
                title: 'Quản lý niên khoá',
                path: paths.home.classroom.school_year,
              },
              {
                title: 'Quản lý khối lớp',
                path: paths.home.classroom.all_grade,
              },
              {
                title: 'Phân bổ học sinh',
                path: paths.home.classroom.student_grant,
              },
            ],
          },

          // {
          //   title: 'Môn học',
          //   path: paths.home.subject.root,
          //   icon: ICONS.subject,
          //   children: [
          //     {
          //       title: 'Quản lý môn học',
          //       path: paths.home.subject.all_subject,
          //     },
          //     {
          //       title: 'Quản lý chủ đề',
          //       path: paths.home.subject.topic,
          //     },
          //   ],
          // },

          {
            title: 'Danh mục',
            path: paths.home.category.root,
            icon: ICONS.category,
            children: [
              {
                title: 'Quản lý tài liệu',
                path: paths.home.category.type,
              },
              {
                title: 'Quản lý bộ sách',
                path: paths.home.category.book,
              },
            ],
          },

          {
            title: 'Học sinh',
            path: paths.home.student.root,
            icon: ICONS.student,
          },

          // {
          //   title: 'Bài thi',
          //   path: paths.home.test.root,
          //   icon: ICONS.test,
          //   children: [
          //     {
          //       title: 'Danh sách câu hỏi',
          //       path: paths.home.test.all_question,
          //     },
          //     {
          //       title: 'Danh sách bài thi',
          //       path: paths.home.test.all_test,
          //     },
          //     {
          //       title: 'In mẫu bộ đề thi',
          //       path: paths.home.test.create_test,
          //     },
          //     {
          //       title: 'Tạo phiếu trả lời',
          //       path: paths.home.test.test_sheet_answer,
          //     },
          //     {
          //       title: 'Bài thi trực tuyến',
          //       path: paths.home.test.online_test,
          //     },
          //   ],
          // },

          {
            title: 'Bảng điểm',
            path: paths.home.transcript.root,
            icon: ICONS.transcript,
            children: [
              {
                title: 'Thống kê điểm học sinh',
                path: paths.home.transcript.transcript_total,
              },
              {
                title: 'Bảng điểm học sinh',
                path: paths.home.transcript.all_transcript,
              },
            ],
          },
        ],
      },
      // Tiện ích
      {
        subheader: 'Tiện ích',
        items: [
          // {
          //   title: 'Chuyển đổi',
          //   path: paths.home.convert.root,
          //   icon: ICONS.convert,
          //   children: [
          //     {
          //       title: 'Văn bản thành giọng nói',
          //       path: paths.home.convert.text_to_voice,
          //     },
          //     {
          //       title: 'Giọng nói thành văn bản',
          //       path: paths.home.convert.voice_to_text,
          //     },
          //     {
          //       title: 'Hình ảnh thành văn bản',
          //       path: paths.home.convert.image_to_text,
          //     },
          //   ],
          // },
          // { title: 'Lịch dạy', path: paths.home.calendar.root, icon: ICONS.calendar },
          // { title: 'Dịch thuật', path: paths.home.translate.root, icon: ICONS.mind_map },
          {
            title: 'Trò chơi',
            path: paths.home.game.root,
            icon: ICONS.game,
            children: [
              {
                title: 'Trò chơi ô chữ',
                path: paths.home.game.crossWord,
              },
              {
                title: 'Trò chơi lật hình',
                path: paths.home.game.memoryGame,
              },
            ],
          },
          // {
          //   title: 'Chat AI',
          //   path: paths.home.chat_ai,
          //   icon: ICONS.chat_ai,
          // },
          {
            title: 'Trực tuyến',
            path: paths.home.learning_online,
            icon: ICONS.learning_online,
          },
          // {
          //   title: 'Đóng gói',
          //   path: paths.home.scorm,
          //   icon: ICONS.scorm,
          // },
        ],
      },
    ],
    []
  );

  return data;
}
