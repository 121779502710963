// @mui
import { enUS, viVN } from '@mui/material/locale';

// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: 'flagpack:gb-nir',
  },

  {
    label: 'Tiếng Việt',
    value: 'vi',
    systemValue: viVN,
    icon: 'flagpack:vn',
  },
];

export const defaultLang = allLangs[1]; // Vietnamese
