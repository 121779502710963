import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import HomeLayout from 'src/layouts/home';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// Page
//
const LessonPlansPrepare = lazy(() => import('src/pages/home/lesson-plans/prepare'));
const LessonPlansWritting = lazy(() => import('src/pages/home/lesson-plans/writting'));
//
const MyLibrary = lazy(() => import('src/pages/home/library/library'));
//
const AllSchoolView = lazy(() => import('src/pages/home/school/school'));
//
const ClassesView = lazy(() => import('src/pages/home/classes/classes'));
const StudentGrantView = lazy(() => import('src/pages/home/classes/student-grant'));
const GradeView = lazy(() => import('src/pages/home/classes/grade'));
const SchoolYearView = lazy(() => import('src/pages/home/classes/school-year'));
//
const AllSubjectView = lazy(() => import('src/pages/home/subject/all-subject'));
const TopicCategory = lazy(() => import('src/pages/home/subject/topic'));
//
const TypeCategory = lazy(() => import('src/pages/home/category/type'));
const BookCategory = lazy(() => import('src/pages/home/category/book'));
//
const ALlStudentView = lazy(() => import('src/pages/home/student/student'));
//
const AllQuestion = lazy(() => import('src/pages/home/test/all-question'));
const AllTestView = lazy(() => import('src/pages/home/test/all-test'));
const CreateText = lazy(() => import('src/pages/home/test/create-test'));
const TestSheetAnswer = lazy(() => import('src/pages/home/test/test-sheet-answer'));
const OnlineTest = lazy(() => import('src/pages/home/test/online-test'));
//
const AllTranscript = lazy(() => import('src/pages/home/transcript/all-transcript'));
const TotalTranscript = lazy(() => import('src/pages/home/transcript/transcript-total'));
//
const VoiceToText = lazy(() => import('src/pages/home/convert/voice-to-text'));
const TextToVoice = lazy(() => import('src/pages/home/convert/text-to-voice'));
const ImageToText = lazy(() => import('src/pages/home/convert/image-to-text'));
//
const CalendarView = lazy(() => import('src/pages/home/calendar/calendar'));
//
const MindMapView = lazy(() => import('src/pages/home/mind-map/mind-map'));
//
const TranslateView = lazy(() => import('src/pages/home/translate/translate'));
//
const GameCrossWord = lazy(() => import('src/pages/home/game/cross-word'));
const MemoryGame = lazy(() => import('src/pages/home/game/memory-game'));
const ChatAI = lazy(() => import('src/pages/home/chat-ai/chat-ai'));
const LearningOnline = lazy(() => import('src/pages/home/learning-online/learning-online'));
const ScormPackage = lazy(() => import('src/pages/home/scorm-package/scorm-package'));

// ----------------------------------------------------------------------

export const homeRoutes = [
  {
    path: 'home',
    element: (
      <HomeLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </HomeLayout>
    ),
    children: [
      { element: <MyLibrary />, index: true },
      {
        path: 'port',
        children: [
          { element: <MyLibrary />, index: true },
          { path: ':port', element: <MyLibrary /> },
        ],
      },
      {
        path: 'lesson-plans',
        children: [
          { element: <LessonPlansPrepare />, index: true },
          { path: 'prepare', element: <LessonPlansPrepare /> },
          { path: 'writing', element: <LessonPlansWritting /> },
        ],
      },

      {
        path: 'library',
        children: [
          { element: <MyLibrary />, index: true },
          { path: ':id', element: <MyLibrary /> },
        ],
      },

      {
        path: 'school',
        children: [{ element: <AllSchoolView />, index: true }],
      },

      {
        path: 'classroom',
        children: [
          { element: <ClassesView />, index: true },
          { path: 'all-classroom', element: <ClassesView /> },
          { path: 'student-grant', element: <StudentGrantView /> },
          { path: 'all-grade', element: <GradeView /> },
          { path: 'school-year', element: <SchoolYearView /> },
        ],
      },

      {
        path: 'subject',
        children: [
          { element: <AllSubjectView />, index: true },
          { path: 'all-subject', element: <AllSubjectView /> },
          { path: 'topic', element: <TopicCategory /> },
        ],
      },

      {
        path: 'category',
        children: [
          { element: <TypeCategory />, index: true },
          { path: 'type', element: <TypeCategory /> },
          { path: 'books', element: <BookCategory /> },
        ],
      },

      {
        path: 'student',
        children: [{ element: <ALlStudentView />, index: true }],
      },

      {
        path: 'test',
        children: [
          { element: <AllQuestion />, index: true },
          { path: 'all-question', element: <AllQuestion /> },
          { path: 'all-test', element: <AllTestView /> },
          { path: 'create-test', element: <CreateText /> },
          { path: 'test-sheet-answer', element: <TestSheetAnswer /> },
          { path: 'online-test', element: <OnlineTest /> },
        ],
      },

      {
        path: 'transcript',
        children: [
          { element: <AllTranscript />, index: true },
          { path: 'all-transcript', element: <AllTranscript /> },
          { path: 'transcript-total', element: <TotalTranscript /> },
        ],
      },

      {
        path: 'convert',
        children: [
          { element: <TextToVoice />, index: true },
          { path: 'text-to-voice', element: <TextToVoice /> },
          { path: 'voice-to-text', element: <VoiceToText /> },
          { path: 'image-to-text', element: <ImageToText /> },
        ],
      },

      {
        path: 'calendar',
        children: [
          {
            element: <CalendarView />,
            index: true,
          },
        ],
      },

      {
        path: 'mind-map',
        children: [
          {
            element: <MindMapView />,
            index: true,
          },
        ],
      },

      {
        path: 'translate',
        children: [
          {
            element: <TranslateView />,
            index: true,
          },
        ],
      },

      {
        path: 'game',
        children: [
          { element: <GameCrossWord />, index: true },
          { path: 'cross-word', element: <GameCrossWord /> },
          { path: 'memory-game', element: <MemoryGame /> },
        ],
      },

      {
        path: 'chat-ai',
        children: [{ element: <ChatAI />, index: true }],
      },

      {
        path: 'learning-online',
        children: [{ element: <LearningOnline />, index: true }],
      },
      {
        path: 'scorm-package',
        children: [{ element: <ScormPackage />, index: true }],
      },
    ],
  },
];
