// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  HOME: '/home',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/login`,
    },
  },

  // HOME
  home: {
    root: `${ROOTS.HOME}/library`,
    introducate: `${ROOTS.HOME}/library`,
    port: (port: string) => `${ROOTS.HOME}/port/${port}`,
    lesson_plans: {
      root: `${ROOTS.HOME}/lesson-plans`,
      prepare_lesson_plans: `${ROOTS.HOME}/lesson-plans/prepare`,
      writing: `${ROOTS.HOME}/lesson-plans/writing`,
    },

    library: {
      root: `${ROOTS.HOME}/library`,
      file: (id: string) => `${ROOTS.HOME}/library/${id}`,
    },
    school: {
      root: `${ROOTS.HOME}/school`,
    },

    classroom: {
      root: `${ROOTS.HOME}/classroom`,
      all_classroom: `${ROOTS.HOME}/classroom/all-classroom`,
      all_grade: `${ROOTS.HOME}/classroom/all-grade`,
      student_grant: `${ROOTS.HOME}/classroom/student-grant`,
      school_year: `${ROOTS.HOME}/classroom/school-year`,
    },

    subject: {
      root: `${ROOTS.HOME}/subject`,
      all_subject: `${ROOTS.HOME}/subject/all-subject`,
      topic: `${ROOTS.HOME}/subject/topic`,
    },

    category: {
      root: `${ROOTS.HOME}/category`,
      type: `${ROOTS.HOME}/category/type`,
      book: `${ROOTS.HOME}/category/books`,
    },

    student: {
      root: `${ROOTS.HOME}/student`,
    },

    lesson: {
      root: `${ROOTS.HOME}/lesson`,
      my_lesson: `${ROOTS.HOME}/lesson/my-lesson`,
    },

    test: {
      root: `${ROOTS.HOME}/test`,
      all_question: `${ROOTS.HOME}/test/all-question`,
      all_test: `${ROOTS.HOME}/test/all-test`,
      create_test: `${ROOTS.HOME}/test/create-test`,
      test_sheet_answer: `${ROOTS.HOME}/test/test-sheet-answer`,
      online_test: `${ROOTS.HOME}/test/online-test`,
    },

    transcript: {
      root: `${ROOTS.HOME}/transcript`,
      transcript_total: `${ROOTS.HOME}/transcript/transcript-total`,
      all_transcript: `${ROOTS.HOME}/transcript/all-transcript`,
    },

    convert: {
      root: `${ROOTS.HOME}/convert`,
      text_to_voice: `${ROOTS.HOME}/convert/text-to-voice`,
      voice_to_text: `${ROOTS.HOME}/convert/voice-to-text`,
      image_to_text: `${ROOTS.HOME}/convert/image-to-text`,
    },

    calendar: {
      root: `${ROOTS.HOME}/calendar`,
    },

    mind_map: {
      root: `${ROOTS.HOME}/mind-map`,
    },

    translate: {
      root: `${ROOTS.HOME}/translate`,
    },

    game: {
      root: `${ROOTS.HOME}/game`,
      crossWord: `${ROOTS.HOME}/game/cross-word`,
      memoryGame: `${ROOTS.HOME}/game/memory-game`,
    },

    chat_ai: `${ROOTS.HOME}/chat-ai`,

    learning_online: `${ROOTS.HOME}/learning-online`,
    scorm: `${ROOTS.HOME}/scorm-package`,
  },
};
